import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wishlistProducts } from '../../components/global/server-api';

// Async Thunks for Wishlist actions
export const fetchWishlist = createAsyncThunk(
    'wishlist/fetchWishlist',
    async () => {
      const response = await wishlistProducts();
      return response.data; // Return the response data (categories with products)
    }
);



const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        wishlist: [],
        loading: false,
        error: null,
    },
    reducers: {
        removeWishlistProduct(state, action) {
            if (state.wishlist.length) {
                const fIndex = state.wishlist.findIndex(x => x.id === action.payload);
                if (fIndex !== -1) state.wishlist.splice(fIndex, 1);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWishlist.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWishlist.fulfilled, (state, action) => {
                state.loading = false;
                state.wishlist = action.payload.data;
            })
            .addCase(fetchWishlist.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    }
});

export const { removeWishlistProduct } = wishlistSlice.actions;

export default wishlistSlice.reducer;