import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { currency } from "../../../utils/currency";
import { NavLink } from 'react-router-dom';

const InvoiceElement = ({order, website}) => {
    let componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        contentRef: componentRef,
        documentTitle: `${order.order_no}-invoice`,
        onPrintError: () => alert("there is an error when printing"),
    });

    return (
        <section className="invoice-section" style={{paddingTop: 0}}>
            <div className="cus-container2">
                <div className="top">
                    <button onClick={() => handlePrint()} className="tf-btn btn-fill animate-hover-btn">
                        Print this invoice 
                    </button>
                </div>
                <div ref={componentRef} className="box-invoice">
                    <div className="header">
                        <div className="wrap-top">
                            <div className="box-left">
                                <NavLink to="#" className="invoice">
                                    <img src="assets/images/logo/top-logo.png" alt="logo" className="logo"/>
                                </NavLink>
                            </div>
                            <div className="box-right">
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div className="title">Invoice #</div>
                                    <span className="code-num">{order.order_no}</span>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-date">
                            <div className="box-left">
                                <label for="">Invoice date:</label>
                                <span className="date">{new Date(order.created_at).toLocaleDateString()}</span>
                            </div>
                            {/* <div className="box-right">
                                <label for="">Due date:</label>
                                <span className="date">03/10/2024</span>
                            </div> */}
                        </div>
                        <div className="wrap-info">
                            <div className="box-left">
                                <div className="title">Supplier</div>
                                <div className="sub">MAN TRANING GROUP PTY LIMITED</div>
                                <p className="desc">{website.address}</p>
                            </div>
                            <div className="box-right">
                                <div className="title">Customer</div>
                                <div className="sub">{order.address.name}</div>
                                <p className="desc">{order.address.address}, {order.address.city}, {order.address.state}<br />{order.address.country}, {order.address.pincode}</p>
                            </div>
                        </div>
                        <div className="wrap-table-invoice">
                            <table className="invoice-table">
                                <thead>
                                    <tr className="title">
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Total</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.items.map(item => <tr key={`it-${item.id}`} className="content">
                                            <td>{item.product.name}</td>
                                            <td>{currency.icon}{item.price}</td>
                                            <td>{item.qty}</td>
                                            <td>{currency.icon}{item.total_price}</td>
                                        </tr>
                                    )}
                                    <tr className="content">
                                        <td className="total">Total Price</td>
                                        <td></td>
                                        <td></td>
                                        <td className="total">{currency.icon}{order.all_total}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    <div className="footer">
                        <ul className="box-contact">
                            <li>https://mantrainingsupplies.com.au</li>
                            <li>{website.email}</li>
                            {website.phone? 
                                <li>{website.phone.split(', ').map(i => <span key={`i-${i}`} style={{marginRight: '5px'}}>{i}</span>)}</li>
                            : null}
                        </ul>
                    </div>
                </div>
            </div>       
        </section>
    );
};

export default InvoiceElement;