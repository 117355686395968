import React, {useEffect, useState, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderData } from '../../../redux/slice/orderSlice';
import InvoiceElement from '../../elements/invoice/InvoiceElement';


const Invoice = () => {
    const { order_no } = useParams();
    const dispatch = useDispatch();
    const {orders, loading} = useSelector(state => state.order);
    const { data: website } = useSelector((state) => state.website || {  });
    const [order, setOrder] = useState(undefined);

    useEffect(() => {
        if (!orders.length) dispatch(fetchOrderData());
    }, []);

    useEffect(() => {
        if (orders.length) {
            const find_data = orders.find(x => x.order_no === +order_no);
            console.log({find_data});
            if (find_data) setOrder(find_data);
        }
    }, [order_no, orders]);

    if (loading || !order) {
        return <p>Loading...</p>
    }


    return (
        <InvoiceElement
            order={order}
            website={website}
        />
    );
}

export default Invoice;
