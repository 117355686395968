import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchPriceRequest } from '../../../redux/slice/priceRequestSlice';
import { addToCart } from '../../../redux/slice/cartSlice';
import { currency } from '../../../utils/currency';
import { formatDate } from '../../../utils/dateFormate';
import Preloader from '../../include/Preloader';
import RequestDetail from './RequestDetail';
import { withSwal } from 'react-sweetalert2';
import { toast } from 'react-toastify';

const Requesting = ({swal}) => {

    const dispatch = useDispatch();
    const [requestId, setRequestId] = useState(null);
    const { data, loading } = useSelector((state) => state.priceRequest);

    useEffect(() => {
        console.log("USE_REQUEST: ", data)
        if (!(data?.data?.length)) dispatch(fetchPriceRequest({}));
    }, []);    

    useEffect(() => {
        const requestList = data;
    }, []);

    const successMsg = message => {
        swal.fire({
            title: 'Success!',
            text: message || 'Price request sent successfully!',
            icon: 'success',
        });
    }

    const errorMsg = message => {
        swal.fire({
            title: 'Error!',
            text: message || 'Failed to send request.',
            icon: 'error',
        });
    };

    const cartToAdd = item => {
        dispatch(addToCart(item))
        toast.success("Product added to Cart!");
    };

    if(loading) {
        return <Preloader/>
    }
    // console.log(data);
    return (
         <React.Fragment>
            <Col lg="9">
                <div className="my-account-content account-order">
                    <div className="wrap-account-order">
                        <table>
                            <thead>
                                <tr>
                                    <th className="fw-6">Product Name</th>
                                    <th className="fw-6">Date</th>
                                    <th className="fw-6">Status</th>
                                    <th className="fw-6">Price</th>
                                    <th className="fw-6">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                data?.data?.length > 0 ? 
                                data?.data.map((x, index) => (
                                    <tr key={`price-${index}`} className="tf-order-item">
                                        <td>
                                            <NavLink to={`/product-detail/${x.product.slug}`} className="fw-6 text-capitalize text-danger">{x.product.name}</NavLink> 
                                        </td>
                                        <td>
                                            {/* Use the formatDate utility to format the created_at date */}
                                            {formatDate(x.created_at)}
                                        </td>
                                        <td>
                                            {/* Show 'Pending' if admin_message is null, otherwise show the message */}
                                            {x.admin_message === null ? "Pending" : x.admin_message}
                                        </td>
                                        <td>
                                            {/* Show 'Not Updated' if admin_price is null, otherwise show the price with currency icon */}
                                            {x.admin_price === null ? "Not Updated" : `${currency.icon}${x.admin_price}`}
                                        </td>
                                        <td>
                                            {x.is_admin_reply ?
                                                <button onClick={() => cartToAdd(x)} className="tf-btn btn-fill animate-hover-btn rounded-0 justify-content-center">
                                                    <span>Add To Cart</span>{/* data-bs-toggle="modal" data-bs-target="#requestModal" */}
                                                </button>
                                            : null}
                                        </td>
                                    </tr>
                                )) : (
                                    <p>No request </p>
                                )
                            }
                                

                            </tbody>
                        </table>
                    </div>
                </div>
            </Col>

            <RequestDetail reqID={requestId}/>
        </React.Fragment>
    );
}

export default withSwal(Requesting);
