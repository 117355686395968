import { configureStore } from '@reduxjs/toolkit';
import productSlice from '../slice/productSlice';
import AuthSlice from '../slice/authSlice';
import SingleProductSlice from '../slice/singleProductSlice';
import categorySlice from '../slice/categorySlice';
import updatePasswordSlice  from '../slice/updatePasswordSlice';
import websiteReducer from '../slice/websiteSlice';
import priceRequestSlice from '../slice/priceRequestSlice';
import blogSlice from '../slice/blogSlice';
import cartSlice from '../slice/cartSlice';
import orderSlice from '../slice/orderSlice';
import wishlistSlice from '../slice/wishlistSlice';


export const store = configureStore({
  reducer: {
    categories: categorySlice,
    products: productSlice,
    blog: blogSlice,
    auth: AuthSlice,
    singleProduct: SingleProductSlice,
    updatePassword: updatePasswordSlice,
    website: websiteReducer,
    priceRequest: priceRequestSlice,
    cart: cartSlice,
    order: orderSlice,
    wishlist: wishlistSlice
  },
});
