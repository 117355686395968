import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderData } from '../../../redux/slice/orderSlice';
import { NavLink, useParams } from 'react-router-dom';
import { BASE_URL } from '../../global/GlobalVariable';
import { currency } from '../../../utils/currency';



const OrderDetail = () => {
    const { order_no } = useParams();
    const dispatch = useDispatch();
    const {orders, loading} = useSelector(state => state.order);
    const [order, setOrder] = useState(undefined);

    useEffect(() => {
        if (!orders.length) dispatch(fetchOrderData());
    }, []);

    useEffect(() => {
        if (orders.length) {
            const find_data = orders.find(x => x.order_no === +order_no);
            console.log({find_data});
            if (find_data) setOrder(find_data);
        }
    }, [order_no, orders]);

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <React.Fragment>
            {!order ? <p>Loading...</p> : 
            <Col lg="9">
                <div className="wd-form-order">
                    <div className="order-head">
                        {/* <figure className="img-product">
                            <img src="assets/images/products/brown.jpg" alt="product"/>
                        </figure> */}
                        <div className="content">
                            <div className="badge">{order.status}</div>
                            <h6 className="mt-8 fw-5">Order #{order.order_no}</h6>
                        </div>
                    </div>
                    <div className="tf-grid-layout md-col-2 gap-15">
                        <div className="item">
                            <div className="text-2 text_black-2">Name</div>
                            <div className="text-2 mt_4 fw-6">{order.address.name}</div>
                        </div>
                        {/* <div className="item">
                            <div className="text-2 text_black-2">Courier</div>
                            <div className="text-2 mt_4 fw-6">Ribbed modal T-shirt</div>
                        </div> */}
                        <div className="item">
                            <div className="text-2 text_black-2">Date</div>
                            <div className="text-2 mt_4 fw-6">{new Date(order.created_at).toLocaleString()}</div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="item mb_20">
                                <div className="text-2 text_black-2">Address</div>
                                <div className="text-2 mt_4 fw-6">{order.address.address}, {order.address.city}, {order.address.state}, {order.address.country}, {order.address.pincode}</div>
                                {order.address.near_by ? <div className="text-2 mt_4 fw-6">{order.address.near_by}</div> : null}
                            </div>
                            {order.status === "Confirmed" ? 
                                <div className="item">
                                    <div className="text-2 text_black-2">Billing</div>
                                    <NavLink to={`/account/my-account-orders-details/${order.order_no}/invoice`} className="tf-btn btn-fill animate-hover-btn">
                                        Invoice 
                                    </NavLink>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="widget-tabs style-has-border widget-order-tab">
                        <ul className="widget-menu-tab">
                            <li className="item-title active">
                                <span className="inner">Product Details</span>
                            </li>
                        </ul>
                        <div className="widget-content-tab">
                          
                            <div className="widget-content-inner active">
                                {order.items.map(item => <div key={`it-${item.id}`} className="order-head">
                                    <figure className="img-product">
                                        <img src={`${BASE_URL}/${item.product.images[0]}`} alt="product"/>
                                    </figure>
                                    <div className="content">
                                        <div className="text-2 fw-6">{item.product.name}</div>
                                        <div className="mt_4"><span className="fw-6">T. Price :</span> {currency.icon}{item.total_price}</div>
                                        <div className="mt_4"><span className="fw-6">Qty :</span> {item.qty}</div>
                                    </div>
                                </div>)}
                                
                                <ul>
                                    {/* <li className="d-flex justify-content-between text-2">
                                        <span>Total Price</span>
                                        <span className="fw-6">$28.95</span>
                                    </li>
                                    <li className="d-flex justify-content-between text-2 mt_4 pb_8 line">
                                        <span>Total Discounts</span>
                                        <span className="fw-6">$10</span>

                                    </li> */}
                                    <li className="d-flex justify-content-between text-2 mt_8">
                                        <span>Order Total</span>
                                        <span className="fw-6">{currency.icon}{order.all_total}</span>
                                    </li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Col>}
        </React.Fragment>
    )
}

export default OrderDetail;
