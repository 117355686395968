import React, { useEffect } from 'react'
import InnerBanner from '../elements/InnerBanner'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWishlist } from '../../redux/slice/wishlistSlice';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { BASE_URL } from '../global/GlobalVariable';
import { toggleWishlist } from '../global/server-api';
import { updateWishlist } from '../../redux/slice/productSlice';
import { removeWishlistProduct } from '../../redux/slice/wishlistSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Wishlist() {

  const dispatch = useDispatch();
  const { wishlist, loading, error } = useSelector((state) => state.wishlist);

  useEffect(() => {
    dispatch(fetchWishlist());
  },[])


  const onToggleWishlist = async product_id => {
    try {
        const response = await toggleWishlist(product_id);
        if (response.data) {
            console.log(response.data);
            const {isWishlist} = response.data;
            dispatch(updateWishlist({productId: product_id, isWishlist}))
            dispatch(removeWishlistProduct(product_id));
            toast.success("Remove Successfully!");
        }
    } catch (error) {
        toast.error(error.response);
        console.log({error});
        console.log(error.response);
    }
  };

  console.log("wishlist___:", wishlist);


  if(loading){
    return <p>Loading</p>
  }
  
  return (
    <React.Fragment>
        <InnerBanner title="Wishlist"/>

        <section className="flat-spacing-2">
          <Container>
            <div className="grid-layout wrapper-shop" data-grid="grid-4">
            {
              wishlist.map((data, index) => (
                <div key={`wishlist-${index}`} className="card-product">
                  <div className="card-product-wrapper">
                    <NavLink to="product-detail.html" className="product-img">
                      <img className="lazyload img-product" data-src={`${BASE_URL}/${data.images[0]}`} src={`${BASE_URL}/${data.images[0]}`} alt="image-product"/>
                      <img className="lazyload img-hover" data-src={`${BASE_URL}/${data.images[1]}`} src={`${BASE_URL}/${data.images[1]}`} alt="image-product"/>
                    </NavLink>
                    <div className="list-product-btn type-wishlist">
                      <button type='button' onClick={() => onToggleWishlist(data.id)}  className="box-icon bg_white wishlist">
                        <span className="tooltip">Remove Wishlist</span>
                        <span className="icon icon-delete"></span>
                      </button>
                    </div>
                    <div className="card-product-info">
                      <NavLink to="product-detail.html" className="title link">{data.name}</NavLink>
                      <span className="price">{data.price}</span>
                    </div>
                  </div>
                </div>
              ))
            }
            </div>
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </Container>
        </section>
        
    </React.Fragment>
  )
}
