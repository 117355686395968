import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import { updateWishlist } from '../../../redux/slice/productSlice';
import { toggleWishlist } from '../../global/server-api';
import { BASE_URL } from '../../global/GlobalVariable';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { currency } from '../../../utils/currency';



const Features = () => {
    const dispatch = useDispatch();
    const [best_products, setBestProducts] = useState([]);
    const [enableLoop, setEnableLoop] = useState(false);
    const [slidersPerView, setSlidesPerView] = useState(4);
    const {products, loading} = useSelector((state) => state.products);
    const {isLoggedIn} = useSelector((state) => state.auth);

    const filterBestProducts = () => {
        if (products.data.length) {
            const bestProducts = products.data.filter(x => x.best_product);
            
            const slidesNum = bestProducts.length < 4 ? bestProducts.length : 4;
            const loop = bestProducts.length >= slidesNum;
            
            setSlidesPerView(slidesNum);
            setEnableLoop(loop);
            setBestProducts(bestProducts);
        }
    };

    useEffect(() => {
        filterBestProducts();
    }, [products.data]);
   

    const onToggleWishlist = async product_id => {
        try {
            const response = await toggleWishlist(product_id);
            if (response.data) {
                console.log(response.data);
                const {isWishlist} = response.data;
                dispatch(updateWishlist({productId: product_id, isWishlist}));
                toast.success(response.data.successMsg);
            }
        } catch (error) {
            console.log({error});
            console.log(error.response);
        }
    };
    // // Check if products.data is present and an array
    // const productData = products.data || []; // Default to empty array if undefined

    // // Conditionally set Swiper options based on the number of categories
    

    return (
        <React.Fragment>
            <section className='flat-spacing-9'>
                <Container>
                    <div className="flat-title wow fadeInUp" data-wow-delay="0s">
                        <span className="title fw-6">Most Popular Products</span>
                    </div>
                    <Swiper 
                       
                        modules={[Navigation, Pagination, Autoplay]}
                        spaceBetween={30}
                        // slidesPerView={4}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 3000 }}
                        speed={2000}
                        loop={enableLoop}
                        breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 30,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 30,
                            },
                        }}
                        className="swiper tf-sw-slideshow"
                    >
                        {
                            loading ? <Skeleton height={50}/> :
                            best_products.length > 0 ? (
                                best_products.map((data) => (
                                    <SwiperSlide key={`product-${data.slug}`}>
                                        <div className="card-product radius-10 style-giftcard">
                                            <div className="card-product-wrapper">
                                                <NavLink to={`/product-detail/${data.slug}`} className="product-img w-100">
                                                    <img 
                                                        className="lazyload img-product" 
                                                        data-src={`${BASE_URL}/${data.images[0]}`} 
                                                        src={`${BASE_URL}/${data.images[0]}`} 
                                                        alt="image-product" 
                                                        style={{height:"289px"}}
                                                    />
                                                    <img 
                                                        class="lazyload img-hover" 
                                                        data-src={`${BASE_URL}/${data.images[1]}`} 
                                                        src={`${BASE_URL}/${data.images[1]}`} 
                                                        alt="image-product"
                                                        style={{height:"289px"}}
                                                    />
                                                </NavLink>
                                                <div className="list-product-btn">
                                                    {isLoggedIn ? 
                                                        <NavLink 
                                                            to={'#'} 
                                                            onClick={() => onToggleWishlist(data.id)} 
                                                            className={data.wishtlist?.length ? "box-icon bg_white wishlist btn-icon-action mr-active" : "box-icon bg_white wishlist btn-icon-action"}>
                                                            <span className="tooltip">{data.wishtlist?.length ? 'Remove From' : 'Add To'} Wishlist</span>
                                                            <span className="icon icon-heart"></span>
                                                        </NavLink>
                                                    : 
                                                        <NavLink to={'/login'} className="box-icon bg_white wishlist btn-icon-action">
                                                            <span className="tooltip">Add to Wishlist</span>
                                                            <span className="icon icon-heart"></span>
                                                        </NavLink>
                                                    }
                                                </div>
                                            </div>
                                            <div className="card-product-info">
                                                <NavLink to={`/product-detail/${data.slug}`} className="title link fw-6 text-capitalize">{data.name.slice(0,25)}</NavLink>
                                                <span className="price text-secondary fw-5"><span>{data.short_description.slice(0,45)}...</span></span>
                                                <div className="tf-product-btns">
                                                    <NavLink to={`/product-detail/${data.slug}`} className="fw-7 fs-12 w-100 d-flex justify-content-center text-capitalize">
                                                        View Detail{data.product_price?.admin_price ? <small>&nbsp;&nbsp;{currency.icon}{data.product_price.admin_price}</small> : ''}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <p>No products available.</p> // Handle case where there are no products
                            )
                        }
                    </Swiper>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default Features;
