import React from 'react';
import ChooseSection from '../elements/home/ChooseSection';
import Features from '../elements/home/Features';
import Categories from '../elements/home/Categories';
import Marque from '../elements/home/Marque';
import Campaign from '../elements/home/Campaign';
import Testimonial from '../elements/Testimonial';
import Blog from '../elements/Blog';
import Slider from '../elements/Slider';
import { ToastContainer } from 'react-toastify';

const Home = () => {
    return (
        <React.Fragment>
            <Slider/>
            <Features/>
            <div className="container"><div className="line"></div></div>
            <ChooseSection/>
            <div className="container"><div className="line"></div></div>
            <Categories/>
            <div className="container"><div className="line"></div></div>
            <Marque/>
            <div className="container"><div className="line"></div></div>
            <Campaign/>
            <div className="container"><div className="line"></div></div>
            <Testimonial/>
            <div className="container"><div className="line"></div></div>
            <Blog/>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </React.Fragment>
    );
}

export default Home;
