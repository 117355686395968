import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountDetail from './AccountDetail';
import Orders from './Orders';
import Address from './Address';
import Dashboard from './Dashboard';
import OrderDetail from './OrderDetail';
import Requesting from './Requesting';
import Invoice from './Invoice';


export default function TabRouting() {

    return (
        <Routes>
            <Route path="my-account-dashboard" element={<Dashboard />} />
            <Route path="my-account-request" element={<Requesting />} />
            <Route path="my-account-orders" element={<Orders/>} />
            <Route path="my-account-orders-details/:order_no" element={<OrderDetail/>} />
            <Route path="my-account-orders-details/:order_no/invoice" element={<Invoice />} />
            <Route path="my-account-address" element={<Address />} />
            <Route path="my-account-edit" element={<AccountDetail/>} />
        </Routes>
    )
}
